header {
	position: fixed;
	top: 8px;
	left: 0;
	margin: 0;
	padding: 0 20px;
	z-index: 999;
	width: 100%;
	height: 50px;
	background-color: #fff;
	box-shadow: 0 0 4px rgba(0,0,0,0.1);

	.printer-status {
		position: absolute;
		right: 10px;
		top: 5px;
	}

	.burger {
		position: relative;
		width: 27px;
		height: 16px;
		margin-top: 17px;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 1px;
			background-color: $blue;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background-color: $blue;
		}

		span {
			position: absolute;
			left: 0;
			top: 8px;
			width: 100%;
			height: 1px;
			background-color: $blue;
		}
	}

	.codes-icon {
		position: absolute;
		left: 75px;
		cursor: pointer;
		top: 13px;

		img {
			width: 24px;
			height: 24px;
			display: block;
		}
	}

	nav {
		background-color: #fff;
		position: fixed;
		left: 0;
		top: 2.6vw;
		width: 220px;
		border-top-right-radius: 40px;
		border-bottom-right-radius: 40px;
		height: calc( 100% - 2.6vw );
		box-shadow: 0 13px 69px 17px rgba(0, 0, 0, 0.27);
		transition: transform .4s $QuadEaseInOut;
		transform: translateX(-270px);
		overflow-y: auto;

		&.open {
			transform: translateX(0);
		}

		.nav-top {
			margin-top: 64px;
			text-align: center;
			padding: 0 26px;

			h3 {
				text-align: right;

				span {
					font-weight: 400;
				}
			}
		}

		.close {
			position: absolute;
			right: 5px;
			top: 2.1vw;
			width: 46px;
			height: 46px;

			&:before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(-45deg);
				width: 20px;
				height: 2px;
				background-color: $blue;
			}

			&:after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(45deg);
				width: 20px;
				height: 2px;
				background-color: $blue;
			}
		}

		.separator {
			position: relative;
			width: calc( 100% - 52px );
			height: 1px;
			background-color: rgba($color: #000000, $alpha: 0.27);
			margin: 0 26px;
		}

		ul {
			padding-top: 30px;

			li {
				text-align: center;

				a, span {
					display: flex;
					flex-flow: row;
					flex-wrap: nowrap;
					align-items: center;
					color: $blue;
					font-size: 15px;
					font-weight: 400;
					justify-content: flex-end;
					text-decoration: none;
					padding: 10px 26px;

					svg {
						margin-left: 10px;
					}
				}
			}
		}
	}
}
