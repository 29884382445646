.calendar-page {
	padding-top: 0;
	padding-left: 3%;
	padding-right: 3%;
}

.calendar {
	position: relative;
	padding-top: 125px;
	padding-left: 0;
	padding-right: 0;
	overflow-x: hidden;

	.calendar-inner {
		width: 100vw;
		max-width: 100vw;
		overflow-x: hidden;
	}

	.calendar-top {
		position: absolute;
		top: 8px;
		right: 3%;
		height: 40px;
		width: auto;
		background-color: transparent;
		z-index: 9999;

		.month {
			position: relative;
			padding-top: 14px;
			font-weight: 700;
			text-transform: capitalize;
			text-align: right;
			z-index: 9999;

			p {
				margin: 0;
				font-size: 1.4em;
			}

			.selected {
				&:before {
					content: "";
					position: absolute;
					left: -26px;
					top: 16px;
					width: 10px;
					height: 10px;
					border-left: $blue solid 2px;
					border-bottom: $blue solid 2px;
					transform: rotate(-45deg);
				}
			}

			.month-dropdown {
				position: absolute;
				top: calc( 100% + 10px );
				right: 0;
				background-color: #fff;
				padding: 10px 15px;
				box-shadow: rgba($color: #000000, $alpha: 0.4) 0px 0px 20px;
				border-radius: 10px;
				opacity: 0;
				visibility: hidden;
				transform: translateY(-30px);
				transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut, transform .3s $CubicEaseInOut;

				&.open {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}
				
				li {
					padding: 10px 0;
				}
			}
		}
	}

	.calendar-header {
		z-index: 50;
		position: relative;
		display: flex;
		top: 0;
		height: 70px;
		width: auto;
		padding: 25px 0;
		padding-left: 0;
		background-color: #fff;

		.calendar-day {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 110px;
			min-width: 110px;
			font-weight: 700;

			.day-name {
				margin-right: 5px;
			}

			.day-no {
				width: 30px;
				height: 30px;
				padding-top: 5px;
				text-align: center;
				font-size: 1.2em;
				border-radius: 50%;
				background-color: #fff;

			}

			&.today {
				.day-no {
					background-color: $orange;
					color: #fff;
				}
			}
		}
	}

	.calendar-columns {
		width: 90%;
		margin-left: 10%;
		display: flex;
		flex-flow: column;
		margin-top: -68px;
		overflow: auto;

		.calendar-columns-inner {
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
		}

		.calendar-column {
			position: relative;
			width: 110px;
			min-width: 110px;
			max-width: 110px;
			height: 680px;
			border-right: 1px solid #d9deeb;

			&:last-child {
				border-right: 0;
			}

			.hour-cell {
				position: relative;
				border-bottom: 1px solid #d9deeb;
				height: 40px;

				@for $i from 1 through 20 {
					&:nth-child(#{$i}) {
						z-index: 20-$i;
					}
				}


				&:last-child {
					border-bottom: 0;
				}
			}

			.event {
				z-index: 20;
				position: absolute;
				margin-top: 3px;
				left: 3%;
				width: 94%;
				padding: 10% 5%;
				font-size: 0.8em;
				color: #fff;
				text-align: center;
				background-color: $light-blue;
				border-radius: 12px;
				pointer-events: none;

				span {
					z-index: 21;
					pointer-events: initial;

					a {
						color: white;
					}
				}
			}

			.availability {
				width: 100%;
				position: absolute;
				margin-top: 3px;
				left: 0;
				border: 2px solid #38559d;
				border-radius: 5px;
				z-index: 30;
				min-height: 36px;
				//pointer-events: none;
				cursor: pointer;

				span {
					display: block;
					height: 8px;
					font-size: .5em;
					text-align: center;
					color: #fff;
					background-color: #38559d;
				}
			}
		}
	}

	.calendar-hour-labels {
		position: absolute;
		top: 120px;
		left: 0;

		.hour-label {
			height: 40px;
			font-size: 0.7em;
		}
	}
}