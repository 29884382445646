.actions {
	.action-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: auto;
		margin: 15px 0;

		.date {
			display: block;
			font-size: 80%;
			margin-bottom: 5px;
		}

		&.active {
			.start-stop {
				&:before {
					display: block;
				}
				&:after {
					display: none;
				}
			}

			.action-name {
				background-color: $green;
			}
		}

		&.cancelled {
			max-width: calc(100% - 55px);
			.action-name {
				background-color: #FF8D03;
			}
		}

		.action-name {
			padding: 14px 30px;
			width: 100%;
			background-color: $light-blue;
			color: #fff;
			font-weight: 700;
			border-radius: 24px;
			transition: .1s background-color $QuadEaseOut;
		}

		.start-stop {
			position: relative;
			width: 45px;
			min-width: 45px;
			max-width: 45px;
			height: 45px;
			margin-left: 10px;
			background-image: linear-gradient(to bottom, #0047af, #002277);
			border-radius: 50%;

			&:after {
				content: "";
				position: absolute;
				top: 15px;
				left: 20px;
				border-left: 10px solid #fff;
				border-right: 10px solid transparent;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
			}

			&:before {
				display: none;
				content: "";
				position: absolute;
				top: 16px;
				left: 16px;
				width: 13px;
				height: 13px;
				background-color: #fff;
			}
		}
	}
}

.action {
	.info {
		margin: 1em;
		color: #ff9310;
	}

	.action-info {
		margin-bottom: 2em;
	}
}

.code-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 10px;
	border-bottom: solid 1px #d8ddea;

	&.active {
		.redeem-btn {
			background-image: linear-gradient(to bottom, #dadada, #bcbcbc);
		}

		.cupon-code {
			opacity: 0.3;
		}
	}

	.nr {
		font-size: 12px;
	}

	.cupon-code {
		font-size: 18px;
		color: #002277;
		font-weight: 700;
	}

	.redeem {
		width: 27px;
		height: 27px;
		border: 1px solid #003da0;
		border-radius: 50%;
	}
}

.reports {
	.reports-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 60px 0 30px;
		height: 45px;
		margin: 15px 0;
		background-color: #0dbae7;
		border-radius: 24px;

		.report-name {
			color: #fff;
			font-weight: 700;
		}

		.date {
			font-size: 14px;
			color: #fff;
		}
	}

	.reports-stats {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		column-gap: 12px;

		&>div {
			min-width: calc( 50% - 6px );
			max-width: calc( 50% - 6px );
			width: calc( 50% - 6px );

			@include XGA {
				min-width: calc( 25% - 10px );
				max-width: calc( 25% - 10px );
				width: calc( 25% - 10px );
			}
		}

		h4 {
			display: flex;
			align-items: center;
			padding: 0 30px 0 30px;
			height: 36px;
			margin: 15px 0;
			background-color: #0dbae7;
			border-radius: 24px;
			color: #fff;
			font-size: 12px;
		}

		.toggle {
			position: absolute;
			left: 40px;
			width: 10px;
			height: 10px;
			border-left: #002277 solid 1px;
			border-bottom: #002277 solid 1px;
			cursor: pointer;
			transform: rotate(-45deg);

			&.open {
				transform: rotate(135deg);
				top: 10px;
			}
		}

		ul.top {
			padding: 0 8px;

			.stat-item {
				position: relative;
				padding: 6px 0;
				border-bottom: #002277 solid 1px;
				margin: 6px 0;

				&:last-child {
					border-bottom: none;
				}
			}

			.sub {
				li {
					position: relative;
					color: #0dbae7;
					margin: 6px 0;
				}
			}

			.count {
				position: absolute;
				right: 0;
			}
		}
	}
}

.report {
	.subtitle {
		font-size: 12px;
		color: $blue;
		text-align: center;
	}

	.report-details {

		.report-detail {
			display: flex;
			justify-content: space-between;
			padding: 8px 15px;
			border-bottom: solid 1px #d8ddea;
			color: $blue;

			.detail-name {
				font-weight: 700;
			}
		}
	}
}

.location {
	display: flex;
	margin: 1em 0;

	.location-icon {
		width: 55px;
	}

	.location-data {
		text-align: left;
		color: $blue;

		.location-id {
			font-weight: 700;
		}

		.location-date {
			font-weight: 700;
		}
	}
}