.mgm {
  padding-left: 10%;
  padding-right: 10%;

  .mgm-account {
    display: flex;
    padding-top: 20px;
    padding-bottom: 30px;
    border-bottom: solid 1px #d2d7e0;

    .points, .ranking {
      width: 50%;
      text-align: center;

      .user-points, .user-placement {
        font-size: 32px;
        font-weight: 700;
      }
    }
  }

  .mgm-user-add {
    padding-top: 30px;
  }

  .mgm-modal-box {
    min-height: 230px;
    margin: 40px 0;
    padding: 10px 30px 30px;
    text-align: center;
    border-radius: 40px;
    box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.17);
  }
}
