.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModalPortal>* {
    opacity: 0;
}

.overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: transparent;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
    }

    &_after-open {
        opacity: 1;
    }

    &_before-close {
        opacity: 0;
    }
}

.modal {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 520px;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 60px;
    outline: none;
    padding: 40px 0;
    margin-bottom: 60px;

    @include vHD {
        padding: 50px 0;
        border-radius: 12px;
    }

    @include XGA {
        width: auto;
        max-width: 800px;
        min-width: 520px;
    }

    .thank-you {
        padding: 0 40px;
        text-align: center;
        color: $blue;

        h2 {
            color: $blue;
        }

        .btn {
            margin-top: 40px;
        }
    }

    .sms-popup,
    .error-popup,
    .email-code,
    .default-popup {
        padding: 0 30px;

        input {
            border-radius: 4px;
        }

        p {
            padding: 0 15px;
            text-align: center;
        }

        a {
            text-decoration: underline;
        }
    }

    .email-code {
        .gray {
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: #AFAFAF;
            margin: 18px 0;
        }

        a {
            color: #002277;
        }

        .buttons-wrapper {
            display: flex;
            gap: 14px;
            margin-top: 18px;

            button:disabled {
                background: linear-gradient(180deg, #BCBCBC 0%, #7E7E7E 100%);
            }
        }
    }

    .popup-inner {
        width: 100%;
        text-align: center;

        p {
            margin-bottom: 14px;

            &.gray {
                color: #414141;
            }
        }

        .btn {
            max-width: 255px;
            margin: 0 auto;
        }

        .buttons-payment {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 30px;

            .btn {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }

        &.printer {
            .center {
                .btn {
                    margin-bottom: 15px;
                }
            }

            .disabled {
                filter: grayscale(80%) brightness(2);
            }

            .badge {
                &.loading {
                    .circle {
                        animation: spin .7s $CubicEaseInOut infinite;

                        &:after {
                            animation: spin2 .7s $CubicEaseInOut infinite;
                        }
                    }

                    .status {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        &:before {
                            content: ". . .";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 100;
                            color: #fff;
                            font-weight: 700;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

.bottom-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: transparent;

    &_after-open {
        opacity: 1;

        .bottom-modal {
            transform: translateY(0);
        }
    }

    &_before-close {
        opacity: 0;

        .bottom-modal {
            transform: translateY(100%);
        }
    }

    .swipe-close {
        width: 100%;
        height: 100%;
    }
}

.bottom-modal {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: calc(100vh - 50px);
    height: calc((var(--vh, 1vh) * 100) - 50px);
    border-top-right-radius: 33px;
    border-top-left-radius: 33px;
    box-shadow: 0 -10px 50px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 10;
    transform: translateY(100%);
    transition: transform .4s $QuadEaseInOut;
    padding: 20px 10px;
    text-align: center;
    outline: none;

    .btn-close {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        background: none;
        border: none;
        display: block;
        text-align: center;
        outline: none;

        .icon {
            position: relative;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-image: linear-gradient(to bottom, #0047af, #002277);
            margin: 0 auto;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        p {
            text-transform: uppercase;
            margin-top: 10px;
            font-size: 10px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 64px;
        height: 5px;
        background-color: #d8d8d8;
        border-radius: 2.5px;
    }

    .popup-inner {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 5%;

        h2 {
            margin-top: 0;
        }

        &.error {

            h2,
            p {
                color: #d80000;
            }
        }

        &.cancel-transaction {
            .buttons-payment {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-top: 30px;
                padding: 0 20px;

                .btn {
                    min-width: 45%;
                    max-width: 45%;
                    width: 45%;
                }
            }
        }
    }

    .popup-codes {
        height: calc(100vh - 250px);
        overflow-y: auto;
    }
}

.popup-overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 300ms $CubicEaseInOut;
    background: transparent;

    &_after-open {
        opacity: 1;
    }

    &_before-close {
        opacity: 0;
    }
}

.popup-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    max-width: 280px;
    width: 100%;
    padding: 30px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.17);

    .popup-inner {
        width: 100%;
        text-align: center;

        h3 {
            margin-top: 0;
            margin-bottom: .5em;
            font-size: 1.2em;
        }

        .buttons {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 30px;

            .btn {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }

        .hours-inputs {
            display: flex;
            align-items: center;

            input {
                margin: 10px 10px 20px;
            }
        }

        .close-btn {
            margin-top: 10px;
        }
    }
}

.overlay.photo {
    background: rgba(0, 0, 0, 0.8);
}

.modal.photo {
    padding: 0;
    width: fit-content;
    height: fit-content;

    .content {
        display: grid;

        * {
            grid-row: 1/2;
            grid-column: 1/2;
        }

        img {
            display: block;
            max-width: 90vw;
            max-height: 80vh;
        }

        button {
            background-color: white;
            z-index: 2;
            width: 24px;
            height: 24px;
            padding: 0;
            display: grid;
            place-items: center;
            border-radius: 50%;
            border: none;
            margin-top: 10px;
            justify-self: flex-end;
            margin-right: 10px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.modal:has(.availability-popup) {
    border-radius: 8px;
    padding: 20px 16px 24px;
}

.availability-popup {
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        .element {
            position: relative;
            margin-top: 10px;

            &:first-of-type {
                margin-top: 0;
            }

            &::before {
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                left: 0;
                top: 0;
                background-color: #45BF71;
                border-radius: 8px;
            }

            img {
                display: block;
                padding-left: 30px;
                height: 24px;
            }
        }


    }

    .upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 48px;

        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #4F4F4F;
            margin: 0;
        }

        button {
            appearance: none;
            border: none;
            background: transparent;
            padding: 5px;
        }
    }

    .active,
    .inactive {
        p {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: #111B29;
            margin: 0 0 8px;
        }

        .element {
            position: relative;
            margin-top: 10px;

            &:first-of-type {
                margin-top: 0;
            }

            &::before {
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                left: 0;
                top: 0;
                background-color: #45BF71;
                border-radius: 8px;
            }

            img {
                display: block;
                padding-left: 40px;
                height: 24px;
            }
        }
    }

    .inactive {
        margin-top: 24px;

        .element::before {
            background-color: #E81E1E;
        }
    }

    button.btn {
        margin: 48px auto 0;
        max-width: 156px;
    }
}

.modal:has(.camera-output) {
    width: 90%;
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    max-width: initial;
    min-width: initial;
}